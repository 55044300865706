import { timeout } from '../lib/timeout';
import { dynamicImportWithRetry } from '../lib/dynamicImportWithRetry';

export function tooltip(Alpine) {
  Alpine.data('tooltip', tooltipComponent);

  function tooltipComponent(text = '') {
    return {
      tippy: null,
      async init() {
        // we can safely postpone loading of the tooltips script
        await timeout(2000);
        const { default: tippy } = await dynamicImportWithRetry(() => import('tippy.js'));
        this.tippy = tippy(this.$root, {
          content: text,
          allowHTML: true,
        });
      },
      destroy() {
        if (this.tippy) this.tippy.destroy();
      },
    };
  }
}
