import { wpRESTGet } from '../lib/wpREST';
import { SearchParamsOption } from 'ky';
import * as Sentry from "@sentry/browser";

type TemplatesResponse = {
  html: string;
};

export async function getTemplate(
  name: string,
  params: SearchParamsOption
): Promise<TemplatesResponse> {
  const result = await wpRESTGet<TemplatesResponse>(`nf/v1/templates/${name}/`, params);
  if (!result) {
    Sentry.captureException(new Error('Fetched template is null.'), {
      tags: {
        type: 'REST',
        method: 'GET',
      },
      extra: {
        template: name,
        params,
      },
    });
  }

  return result;
}
