class WpRequestError extends Error {
  public url: string;
  public status: string;
  public statusText: string;

  constructor(message, response) {
    super(message);

    if (response) {
      this.url = response.url;
      this.status = response.status;
      this.statusText = response.statusText;
    }
  }
}

export { WpRequestError };
