export function product(Alpine) {
  Alpine.data('product', productComponent);

  function productComponent(id, { ecommerceList = null } = {}) {
    return {
      isFavorite: false,
      get item() {
        return this.$store.cart.get(id);
      },
      get qty() {
        return this.item?.quantity ?? 0;
      },
      get hasDiscount() {
        return (
          this.item && this.item.discountPrice > 0 && this.item.discountPrice < this.item.price
        );
      },
      get totalPrice() {
        if (!this.item) return 0;

        const price = this.hasDiscount ? this.item.discountPrice : this.item.price;
        return price * this.item.quantity;
      },
      get totalOldPrice() {
        if (!this.item) return 0;

        return this.item.price * this.item.quantity;
      },
      init() {
        this.$watch('$store.favorites.items', (items) => {
          this.isFavorite = items.includes(id);
        });
      },
      destroy() {},
      async add() {
        await this.$store.cart.add(id, { ecommerceList });
        this.$store.header.show();
      },
      async toggle() {
        if (!this.qty) {
          await this.add();
        } else {
          await this.$store.cart.remove(id);
        }
      },
      increment() {
        if (!this.qty) {
          this.add();
        } else {
          this.$store.cart.setQuantity(id, this.qty + 1);
        }
      },
      decrement() {
        if (this.qty > 1) {
          this.$store.cart.setQuantity(id, this.qty - 1);
        } else {
          this.$store.cart.remove(id);
        }
      },
      onInputChange(e) {
        const qty = parseInt(e.target.value.trim(), 10);

        if (isNaN(qty)) {
          return;
        } else if (qty < 0) {
          this.$store.cart.setQuantity(id, 1);
        } else if (qty === 0) {
          this.$store.cart.remove(id);
        } else {
          this.$store.cart.setQuantity(id, qty);
        }
      },
      onInputBlur(e) {
        const qty = parseInt(e.target.value.trim(), 10);
        if (!qty) e.target.value = this.qty;
      },
    };
  }
}
