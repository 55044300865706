import Swiper, { Navigation, Pagination } from 'swiper';

export function oneImageSlider(Alpine) {
  Alpine.data('oneImageSlider', oneImageSliderComponent);

  function oneImageSliderComponent() {
    return {
      swiper: null,
      init() {
        this.$nextTick(() => {
          this.swiper = new Swiper(this.$refs.swiper, {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            spaceBetween: 16,
            navigation: {
              nextEl: this.$refs.next,
              prevEl: this.$refs.prev,
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
          });
        });
      },
      destroy() {
        if (this.swiper) this.swiper.destroy();
      },
    };
  }
}
