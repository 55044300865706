import Swiper, { Navigation } from 'swiper';
import { md, sm } from '../variables';
import { loadLightgallery } from '../lib/loadLightgallery';

export function lettersSlider(Alpine) {
  Alpine.data('lettersSlider', lettersSliderComponent);

  function lettersSliderComponent({ isLazy = true } = {}) {
    return {
      swiper: null,
      loaded: !isLazy,
      init() {
        Alpine.effect(() => {
          if (!this.loaded) return;

          loadLightgallery().then(({ default: lightGallery }) => {
            lightGallery(this.$refs.swiper, {
              speed: 500,
              licenseKey: '0000-0000-000-0000',
              selector: '.letters__link',
              counter: false,
              download: false,
              mobileSettings: {
                controls: false,
                showCloseIcon: true,
                download: false,
              },
            });
          });

          this.swiper = new Swiper(this.$refs.swiper, {
            modules: [Navigation],
            watchSlidesProgress: true,
            preloadImages: false,
            slidesPerView: 'auto',
            spaceBetween: 8,
            navigation: {
              nextEl: this.$refs.next,
              prevEl: this.$refs.prev,
            },
            breakpoints: {
              [sm]: {
                slidesPerView: 5,
              },
              [md]: {
                slidesPerView: 6,
                spaceBetween: 16,
              },
            },
          });
        });
      },
      destroy() {
        this.swiper?.destroy();
      },
    };
  }
}
