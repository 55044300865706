export function vkSendAddToCart() {
  if (typeof VK === 'undefined') return;

  VK.Goal('add_to_cart');
}

export function vkSendPaymentInfo() {
  if (typeof VK === 'undefined') return;

  VK.Goal('add_payment_info');
}

export function vkSendLead() {
  if (typeof VK === 'undefined') return;

  VK.Goal('lead');
}

export function vkSendPurchase() {
  if (typeof VK === 'undefined') return;

  VK.Goal('purchase');
}
