/**
 * Detects whether cookie support is enabled.
 * https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/cookies.js
 */
export function isCookieEnabled(): boolean {
  try {
    // Create cookie
    document.cookie = 'cookietest=1';
    const ret = document.cookie.indexOf('cookietest=') !== -1;
    // Delete cookie
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
    return ret;
  } catch (e) {
    return false;
  }
}

/**
 * Detects whether cookie support is enabled.
 * https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js
 */
export function isLocalstorageEnabled(): boolean {
  const key = 'isLocalstorageEnabled';
  try {
    localStorage.setItem(key, key);
    localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
}

export function browserFeatures() {
  return {
    isCookieEnabled: isCookieEnabled(),
    isLocalstorageEnabled: isLocalstorageEnabled(),
  };
}
