import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

// manually polyfill throwIfAborted, as abortcontroller-polyfill does not include it
if (!AbortSignal.prototype.hasOwnProperty('throwIfAborted')) {
  // https://github.com/nodejs/node/pull/40951/files#diff-03bd4f07a1006cb0daaddced702858751b20f5ab7681cb0719c1b1d80d6ca05c
  AbortSignal.prototype.throwIfAborted = function () {
    if (this.aborted) {
      const reason = this.reason
        ? this.reason
        : new DOMException('This operation was aborted', 'AbortError');

      throw reason;
    }
  };
}
