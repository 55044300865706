import { wpRESTGet } from '../lib/wpREST';

export type GetDeliveryZoneResponse = {
  price: number;
  zone: string;
  prices: {
    shop_courier: number;
    costume_courier: number;
    service_courier: number;
  };
} | null;

export type GetAddressResponse = string | null;

export async function getDeliveryZone(address: string): Promise<GetDeliveryZoneResponse> {
  return wpRESTGet<GetDeliveryZoneResponse>('nf/v1/geo/delivery_zone/', { address });
}

export async function getAddress(lat: number, lon: number): Promise<GetAddressResponse> {
  return wpRESTGet<GetAddressResponse>('nf/v1/geo/address/', { lat, lon });
}
