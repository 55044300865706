import { timeout } from '../lib/timeout';
import { disableScroll, enableScroll } from '../lib/scroll';
import { onIdle, onPageLoad } from '../lib/promises';

export function bx24widget(Alpine) {
  Alpine.data('bx24widget', bx24widgetComponent);

  function bx24widgetComponent() {
    return {
      isLoaded: false,
      async init() {
        this.onWidgetVisibilityChange((isOpen) => {
          if (isOpen) {
            disableScroll();
          } else {
            enableScroll();
          }
        });

        await this.loadWidget();
      },
      async loadWidget() {
        if (this.isLoaded) return;
        await onPageLoad();
        await timeout(1000);
        await onIdle(5000);

        (function (w, d, u) {
          const s = d.createElement('script');
          s.async = true;
          s.src = u + '?' + ((Date.now() / 60000) | 0);
          const h = d.getElementsByTagName('script')[0];
          h.parentNode.insertBefore(s, h);
        })(
          window,
          document,
          'https://cdn-ru.bitrix24.ru/b2967651/crm/site_button/loader_2_hz0rl9.js'
        );

        this.isLoaded = true;
      },
      onWidgetVisibilityChange(callback = (isOpen: boolean) => {}) {
        const htmlElement = document.documentElement;
        const openClassname = 'crm-widget-button-mobile';

        const observer = new MutationObserver((mutationList, observer) => {
          mutationList.forEach((mutation) => {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
              const oldClass = mutation.oldValue ?? '';
              if (
                htmlElement.classList.contains(openClassname) &&
                !oldClass.includes(openClassname)
              ) {
                callback(true);
              } else if (
                !htmlElement.classList.contains(openClassname) &&
                oldClass.includes(openClassname)
              ) {
                callback(false);
              }
            }
          });
        });

        observer.observe(htmlElement, { attributes: true, attributeOldValue: true });
      },
    };
  }
}
