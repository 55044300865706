import { wpRESTPostMultipart } from '../lib/wpREST';
import { isHttpUrl } from '../lib/url';

type UploadedImage = {
  url: string;
  size: number;
  mimeType: string;
  formattedType: string;
};

const maxSize = 5; // Mib
const permittedMimeTypes = ['image/jpeg', 'image/png', 'image/pjpeg', 'image/x-jps'];

export function imageAttach(Alpine) {
  Alpine.data('imageAttach', imageAttachComponent);

  function imageAttachComponent() {
    return {
      linkInput: '',
      previewURL: '',
      postcardURL: '',

      isUploading: false,
      error: { title: '', text: '' },
      uploadedImage: null,

      init() {},

      clear() {
        this.error = {};
        this.linkInput = '';
        this.postcardURL = '';
        this.uploadedImage = null;
      },

      async handleFileChange(e) {
        const file = e.target.files[0];

        if (file.size > maxSize * 1024 * 1024) {
          this.error = {
            title: 'Слишком большой объем изображения',
            text: `Загрузите изображение объемом не более ${maxSize} Мб`,
          };
        }

        this.isUploading = true;
        try {
          this.uploadedImage = await wpRESTPostMultipart<UploadedImage>(
            'nf/v1/postcard_picture/',
            {},
            { postcard: file }
          );
        } catch (e) {
          this.error = {
            title: 'Ошибка загрузки изображения',
            text: 'Попробуйте повторить попытку или загрузите другое',
          };
        }
        this.isUploading = false;
      },

      async handleLinkUpload() {
        const link = this.linkInput.trim();
        if (!isHttpUrl(link)) return;
      },
    };
  }
}
