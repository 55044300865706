import Swiper, { Pagination, Thumbs, FreeMode, Navigation, Mousewheel } from 'swiper';
import { loadPlyr } from '../lib/loadPlyr';

function init() {
  const gallery = document.querySelector('.js-product-gallery');
  if (!gallery) return;

  const thumbNext = gallery.querySelector('.product-gallery__thumbnails-next') as HTMLElement;
  const thumbPrev = gallery.querySelector('.product-gallery__thumbnails-prev') as HTMLElement;
  const thumbsSwiper = new Swiper('.js-product-gallery .product-gallery__thumbnails .swiper', {
    modules: [FreeMode, Mousewheel, Navigation],
    direction: 'vertical',
    slidesPerView: 'auto',
    spaceBetween: 5,
    watchSlidesProgress: true,
    mousewheel: true,
    freeMode: true,
    navigation: {
      nextEl: thumbNext,
      prevEl: thumbPrev,
    },
  });

  const next = gallery.querySelector('.product-gallery__next') as HTMLElement;
  const prev = gallery.querySelector('.product-gallery__prev') as HTMLElement;
  new Swiper('.js-product-gallery .product-gallery__slider .swiper', {
    modules: [Pagination, Thumbs, Navigation],
    slidesPerView: 1,
    spaceBetween: 16,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: next,
      prevEl: prev,
    },
    thumbs: {
      swiper: thumbsSwiper,
    },
  });

  const videos = gallery.querySelectorAll<HTMLElement>('.plyr');
  if (videos.length > 0) {
    loadPlyr().then((Plyr) => {
      videos.forEach((video) => {
        // @ts-ignore
        new Plyr(video, { ratio: '1:1' });
      });
    });
  }
}

init();
