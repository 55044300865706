import { getYmaps } from './services';

const mapPinIcon =
  "data:image/svg+xml,%3Csvg width='60' height='68' viewBox='0 0 60 68' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_928_90)'%3E%3Cg filter='url(%23filter0_f_928_90)'%3E%3Cpath d='M30.51 56.523a.5.5 0 01-.5.477c-.29 0-.51-.21-.52-.477-.145-3.168-1.756-5.217-4.832-6.147C14.53 47.968 7 38.863 7 28 7 15.297 17.297 5 30 5s23 10.297 23 23c0 10.863-7.53 19.968-17.658 22.376-3.076.93-4.687 2.98-4.83 6.147h-.002z' fill='%23FA3535'/%3E%3C/g%3E%3Cpath d='M30.51 56.523a.5.5 0 01-.5.477c-.29 0-.51-.21-.52-.477-.145-3.168-1.756-5.217-4.832-6.147C14.53 47.968 7 38.863 7 28 7 15.297 17.297 5 30 5s23 10.297 23 23c0 10.863-7.53 19.968-17.658 22.376-3.076.93-4.687 2.98-4.83 6.147h-.002z' fill='%23FA3535'/%3E%3Cpath d='M30 68c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z' fill='%23fff'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M30 66a2 2 0 10.09-3.998A2 2 0 0030 66z' fill='%23FA3535'/%3E%3Cpath d='M30.003 22s3.047-.648 4.913-2.99l.128-.01s4.54 10-5.04 10-5.043-10-5.043-10a8.912 8.912 0 012.905.736c.617-1.226 1.378-2.234 2.137-2.736.702.464 1.403 1.357 1.991 2.456-.553 1.585-1.99 2.544-1.99 2.544zm-1.3 14.311c-5.876 1.11-7.08-2.974-6.612-5.311 1.65 0 4.793 0 6.74 2.6l.172-3.6h2l.5 10.5s-.75.5-1.5.5-1.5-.5-1.5-.5l.2-4.189zm3.673.159c4.894.392 5.972-3.296 5.537-5.47-1.402 0-3.925 0-5.86 1.598l.322 3.871.001.001z' fill='%23fff'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_928_90'%3E%3Cpath fill='%23fff' d='M0 0h60v68H0z'/%3E%3C/clipPath%3E%3Cfilter id='filter0_f_928_90' x='1' y='-1' width='58' height='64' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='3' result='effect1_foregroundBlur_928_90'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A";

export async function initStoreLocationMap(mapElement) {
  const ymaps = await getYmaps();
  const map = new ymaps.Map(
    mapElement,
    {
      center: [59.978057, 30.34775],
      zoom: 11,
      controls: ['zoomControl'],
    },
    {
      suppressMapOpenBlock: true,
    }
  );

  const myPlacemark = new ymaps.Placemark(
    [59.97807, 30.34775],
    {},
    {
      iconLayout: 'default#imageWithContent',
      iconImageHref: mapPinIcon,
      iconImageSize: [60, 68],
      iconImageOffset: [-30, -58],
    }
  );
  map.geoObjects.add(myPlacemark);
}
