import { getNearestAvailableDate, isToday, managersWorkingHours } from '../lib/deliveryTime';
import { loadFlatpickr } from '../lib/loadFlatpickr';

type DateSelectParams = {
  minDate?: 'today' | 'nearestOpen';
};

export function dateSelect(Alpine) {
  Alpine.data('dateSelect', dateSelectComponent);

  function dateSelectComponent({ minDate }: DateSelectParams = {}) {
    return {
      isInputFocused: false,
      flatpickr: null,
      value: '',
      error: '',
      get isEmpty() {
        return this.value.toString().length === 0;
      },
      get isFocused() {
        return this.isInputFocused || !this.isEmpty;
      },
      async init() {
        await this.$nextTick();

        const { flatpickr, Russian } = await loadFlatpickr();

        let minDateParam = null;
        if (minDate === 'today') {
          minDateParam = 'today';
        } else if (minDate === 'nearestOpen') {
          const nearestDate = getNearestAvailableDate(managersWorkingHours.to);
          minDateParam = isToday(nearestDate) ? 'today' : nearestDate.toDate();
        }

        this.flatpickr = flatpickr(this.$refs.dateInput, {
          locale: Russian,
          defaultDate: this.dateValue,
          disableMobile: true,
          static: true,
          minDate: minDateParam,
          dateFormat: 'd.m.Y',
          altInput: true,
          altFormat: 'l, j M',
          monthSelectorType: 'static',
          prevArrow: '<span class="i i--chevron-left"></span>',
          nextArrow: '<span class="i i--chevron-right"></span>',
          onOpen: () => (this.isInputFocused = true),
          onClose: () => (this.isInputFocused = false),
          onChange: (selectedDates, dateStr, instance) => {
            this.value = dateStr;
          },
        });

        this.$watch('value', (value) => this.flatpickr.setDate(value, false, 'd.m.Y'));
        if (this.value) {
          this.flatpickr.setDate(this.value, false, 'd.m.Y');
        }
      },
    };
  }
}
