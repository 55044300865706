import * as Sentry from '@sentry/browser';
import ky, { SearchParamsOption, TimeoutError } from 'ky';
import { requestTimeout } from '../variables';
import { timeout } from './timeout';

export async function wpRESTPost<T>(endpoint: string, data: Object = {}): Promise<T> {
  const uri = globalThis.wpParams.restURL + endpoint;
  try {
    return (await ky
      .post(uri, {
        json: data,
        timeout: requestTimeout,
      })
      .json()) as T;
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'REST',
        method: 'POST',
      },
      extra: {
        uri,
        data,
      },
    });
    return null;
  }
}

export async function wpRESTPut<T>(endpoint: string, data: Object = {}): Promise<T> {
  const uri = globalThis.wpParams.restURL + endpoint;
  try {
    return (await ky
      .put(uri, {
        json: data,
        timeout: requestTimeout,
      })
      .json()) as T;
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'REST',
        method: 'PUT',
      },
      extra: {
        uri,
        data,
      },
    });
    return null;
  }
}

export async function wpRESTDelete<T>(endpoint: string, data: Object = {}): Promise<T> {
  const uri = globalThis.wpParams.restURL + endpoint;
  try {
    return (await ky
      .delete(uri, {
        json: data,
        timeout: requestTimeout,
      })
      .json()) as T;
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'REST',
        method: 'DELETE',
      },
      extra: {
        uri,
        data,
      },
    });
    return null;
  }
}

export async function wpRESTPostMultipart<T>(
  endpoint: string,
  data: Object,
  files: Object = {}
): Promise<T> {
  const uri = globalThis.wpParams.restURL + endpoint;
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'object') value = JSON.stringify(value);
    formData.append(key, value.toString());
  });

  Object.entries(files).forEach(([key, value]) => {
    formData.append(key, value);
  });

  try {
    return (await ky
      .post(uri, {
        timeout: requestTimeout,
        body: formData,
      })
      .json()) as T;
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'REST',
        method: 'POST',
      },
      extra: {
        uri,
        formData,
      },
    });
    return null;
  }
}

async function _wpRESTGet<T>(uri: string, params?: SearchParamsOption): Promise<T> {
  return (await ky
    .get(uri, {
      searchParams: params,
      timeout: requestTimeout,
      retry: {
        limit: 3,
        statusCodes: [408, 413, 429, 500, 502, 503, 504],
      },
    })
    .json()) as T;
}

export async function wpRESTGet<T>(
  endpoint: string,
  params?: SearchParamsOption,
  maxRetries: number = 3,
  retryDelay: number = 1000
): Promise<T> {
  const uri = window.wpParams.restURL + endpoint;

  let error;
  let retry = 0;

  for (; retry < maxRetries; retry++) {
    try {
      return await _wpRESTGet(uri, params);
    } catch (e) {
      error = e;
      const msg = `wpRESTGet ${e.name} exception${retry > 0 ? `, retry ${retry}` : ''}`;
      console.error(msg, e);

      if (!(e instanceof TypeError) && !(e instanceof TimeoutError)) {
        break;
      }
    }

    await timeout(retryDelay);
  }

  Sentry.captureException(error, {
    tags: {
      type: 'REST',
      method: 'GET',
    },
    extra: {
      uri,
      params,
      retry,
    },
  });

  return null;
}
