export function isScrollDisabled() {
  return document.documentElement.classList.contains('is-no-scroll');
}

export function disableScroll() {
  if (!isScrollDisabled()) {
    document.documentElement.classList.add('is-no-scroll');
  }
}

export function enableScroll() {
  if (isScrollDisabled()) {
    document.documentElement.classList.remove('is-no-scroll');
  }
}
