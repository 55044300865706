export function infoDialogStore(Alpine) {
  Alpine.store('infoDialog', {
    isOpen: false,
    title: '',
    content: '',

    get isHeaderShown() {
      return this._isShown || this.isCatalogOpen || this.isSearchOpen;
    },
    open(title = '', content = '') {
      this.isOpen = true;
      this.title = title;
      this.content = content;
    },
    close() {
      this.isOpen = false;
    },
    setContent(content: string) {
      this.content = content;
    },
  });
}
