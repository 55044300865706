import { getTemplate } from '../api/templates';
import { SearchParamsOption } from 'ky';

export function preloadTemplate(Alpine) {
  Alpine.data('preloadTemplate', preloadTemplateComponent);

  function preloadTemplateComponent(name: string, params: SearchParamsOption) {
    return {
      isInit: false,
      init() {},
      async preload() {
        const result = await getTemplate(name, params);
        if (result) {
          this.$root.outerHTML = result.html;
        }
      },
      destroy() {},
    };
  }
}
