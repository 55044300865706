import get from 'just-safe-get';
import enquire from 'enquire.js';
import { md } from '../variables';
import { disableScroll, enableScroll } from '../lib/scroll';
import { timeout } from '../lib/timeout';
import { dynamicImportWithRetry } from '../lib/dynamicImportWithRetry';

export function select(Alpine) {
  Alpine.data('select', selectComponent);

  function selectComponent({
    initialValue = '',
    model = 'value',
    errorParam = 'errorMessage',
  } = {}) {
    return {
      value: initialValue,
      tomSelect: null,
      isSelectFocused: false,
      isScrollPrevented: false,
      errorMessage: '',
      get isEmpty() {
        const value = get(this, model, '');
        return value.toString().length === 0;
      },
      get isFocused() {
        return this.isInputFocused || !this.isEmpty;
      },
      get error() {
        return get(this, errorParam, '');
      },
      select: {
        'x-model': model,
        ['x-ref']: 'select',
      },
      label: {
        [':class']() {
          return {
            'is-focused': this.isFocused,
            'has-error': this.error,
          };
        },
      },
      async init() {
        // we can postpone initialization of dropdowns
        await timeout(1000);
        const TomSelect = await dynamicImportWithRetry(() => import('tom-select'));
        const label = this.$refs.label?.textContent;
        this.$refs.select.disabled = false;

        // @ts-ignore
        this.tomSelect = new TomSelect(this.$refs.select, {
          controlInput: null,
          copyClassesToDropdown: false,
          wrapperClass: 'select__wrapper',
          controlClass: 'select__control',
          dropdownClass: 'select__dropdown',
          dropdownContentClass: 'select__dropdown-content',
          itemClass: 'select__item',
          optionClass: 'select__option',
          render: {
            dropdown: () => `<div><div class="select__dropdown-heading">${label}</div></div>`,
          },
        });

        this.tomSelect.on('focus', () => (this.isSelectFocused = true));
        this.tomSelect.on('blur', () => (this.isSelectFocused = false));

        this.tomSelect.on('dropdown_open', () => {
          if (window.innerWidth < md) {
            disableScroll();
            this.isScrollPrevented = true;
          }
        });
        this.tomSelect.on('dropdown_close', () => {
          if (this.isScrollPrevented) {
            enableScroll();
            this.isScrollPrevented = false;
          }
        });

        enquire.register(`screen and (min-width: ${md}px)`, {
          match: () => {
            if (this.isScrollPrevented) {
              enableScroll();
              this.isScrollPrevented = false;
            }
          },
        });

        this.$watch(model, (newValue) => {
          this.tomSelect.addItem(newValue, true);
        });
        this.tomSelect.addItem(get(this, model, ''), true);
      },
      destroy() {
        if (this.tomSelect) this.tomSelect.destroy();
      },
    };
  }
}
