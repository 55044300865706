import { toNumbersOnly } from './utils';

export type ValidationRule<T> = (data: T) => boolean;

export type ErrorMessage = string;
export type ErrorMessages<T> = Partial<Record<keyof T, ErrorMessage>>;
export type ValidationCheck<T> = {
  validate: ValidationRule<T>;
  error: ErrorMessage;
};
export type ValidationChecks<T> = Partial<Record<keyof T, ValidationCheck<T>[]>>;

export type ValidationResult<T> = {
  isValid: boolean;
  errors: ErrorMessages<T>;
};

type List<T> = T[];

export function all<T>(rules: List<ValidationRule<T>>): ValidationRule<T> {
  return (data) => rules.every((isValid) => isValid(data));
}

export function some<T>(rules: List<ValidationRule<T>>): ValidationRule<T> {
  return (data) => rules.some((isValid) => isValid(data));
}

export const isInternational = (value: string) => value.startsWith('+');
export const isPhone = (value: string) =>
  isInternational(value) && toNumbersOnly(value).length >= 11;

export const isEmail = (value: string) =>
  /^[\p{Letter}\d\.\+\-_]+?@[\p{Letter}\d\.\-_]+\.[a-zA-Z0-9][a-zA-Z0-9]+$/u.test(value.trim());

export function createValidator<T>(checks: ValidationChecks<T>) {
  return function validate(data: T): ValidationResult<T> {
    const result: ValidationResult<T> = {
      isValid: true,
      errors: {},
    };

    Object.keys(checks).forEach((key) => {
      const field = key as keyof T;
      const fieldRules = checks[field];
      if (!fieldRules) return;

      for (let check of fieldRules) {
        if (!check.validate) continue;
        if (!check.validate(data)) {
          result.isValid = false;
          result.errors[field] = check.error;
          break;
        }
      }
    });

    return result;
  };
}
