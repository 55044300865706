import Swiper from 'swiper';
import { md, sm } from '../variables';

export function subcategories(Alpine) {
  Alpine.data('subcategories', subcategoriesComponent);

  function subcategoriesComponent() {
    return {
      swiper: null,
      init() {
        this.swiper = new Swiper(this.$refs.swiper, {
          slidesPerView: 'auto',
          spaceBetween: 8,
          breakpoints: {
            [sm]: {
              spaceBetween: 12,
            },
            [md]: {
              enabled: false,
            },
          },
        });
      },
      destroy() {
        this.swiper?.destroy();
      },
    };
  }
}
