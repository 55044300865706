import Swiper, { Navigation } from 'swiper';

export function promotionsSlider(Alpine) {
  Alpine.data('promotionsSlider', promotionsSliderComponent);

  function promotionsSliderComponent({ isLazy = true } = {}) {
    return {
      swiper: null,
      loaded: !isLazy,
      init() {
        Alpine.effect(() => {
          if (!this.loaded) return;

          this.swiper = new Swiper(this.$refs.swiper, {
            modules: [Navigation],
            watchSlidesProgress: true,
            preloadImages: false,
            slidesPerView: 1,
            spaceBetween: 8,
            navigation: {
              nextEl: this.$refs.next,
              prevEl: this.$refs.prev,
            },
          });
        });
      },
      destroy() {
        this.swiper?.destroy();
      },
    };
  }
}
