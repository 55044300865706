import Swiper, { FreeMode } from 'swiper';

export function filterChips(Alpine) {
  Alpine.data('filterChips', filterChipsComponent);

  function filterChipsComponent() {
    return {
      swiper: null,
      init() {
        // this.$root.querySelectorAll('.swiper-slide').forEach((slide) => (slide.style.width = 'auto'));

        new Swiper(this.$root, {
          modules: [FreeMode],
          direction: 'horizontal',
          freeMode: true,
          slidesPerView: 'auto',
          mousewheel: true,
          breakpoints: {
            768: {
              enabled: false,
            },
          },
        });
      },
      destroy() {
        if (this.swiper) this.swiper.destroy();
      },
    };
  }
}
