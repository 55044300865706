import { wpRESTDelete, wpRESTGet, wpRESTPost, wpRESTPut } from '../lib/wpREST';
import { Cart, CartItem } from '../lib/cart';

export type GetCartResponse = { cart: Cart };
export type AddProductToCartResponse = { cart: Cart; addedItem: CartItem };
export type SetCartProductQuantityResponse = { cart: Cart; changedItem: CartItem };

export async function getCart(): Promise<GetCartResponse> {
  return (await wpRESTGet<GetCartResponse>('nf/v1/cart/')) ?? { cart: {} };
}

export async function addProductToCart(
  productId: number,
  { ecommerceList = null } = {}
): Promise<AddProductToCartResponse> {
  return await wpRESTPost<AddProductToCartResponse>('nf/v1/cart/', {
    product_id: productId,
    ecommerce_list: ecommerceList,
  });
}

export async function clearCart(): Promise<undefined> {
  await wpRESTDelete('nf/v1/cart/');
  return;
}

export async function removeProductFromCart(productId: number): Promise<GetCartResponse> {
  return await wpRESTDelete<GetCartResponse>(`nf/v1/cart/${productId}`);
}

export async function setCartProductQuantity(
  productId: number,
  quantity: number
): Promise<SetCartProductQuantityResponse> {
  return await wpRESTPut(`nf/v1/cart/${productId}`, {
    quantity: quantity,
  });
}
