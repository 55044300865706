import omit from 'just-omit';
import { collectPropsFromElement } from '../lib/collectPropsFromElement';
import {
  cartItemToEcommerceProduct,
  ecommerceAddProducts,
  ecommerceRemoveProducts,
} from '../lib/enhancedEcommerce';
import { Cart } from '../lib/cart';
import {
  addProductToCart,
  clearCart,
  getCart,
  removeProductFromCart,
  setCartProductQuantity,
} from '../api/cart';
import { vkSendAddToCart } from '../lib/vkPixel';
import { showToast } from '../components/toast';
import { addLifetimeCartAddition } from '../api/product';

export function cartStore(Alpine) {
  const items: Cart = {};

  Alpine.store('cart', {
    items,
    get count() {
      return Object.keys(this.items).length;
    },
    get countAll() {
      return this.list().reduce((acc, item) => acc + item.quantity, 0);
    },
    get price() {
      return this.list().reduce(
        (acc, item) =>
          acc +
          (item.discountPrice > 0 && item.discountPrice < item.price
            ? item.discountPrice
            : item.price) *
            item.quantity,
        0
      );
    },
    get oldPrice() {
      return this.list().reduce((acc, item) => acc + item.price * item.quantity, 0);
    },
    get discount() {
      return this.oldPrice - this.price;
    },
    get priceClients() {
      return this.list().reduce(
        (acc, item) =>
          acc +
          (item.priceClients > 0 && item.priceClients < item.price
            ? item.priceClients
            : item.price) *
            item.quantity,
        0
      );
    },
    async init() {
      const hydrationElement = document.getElementById('cart-hydration');
      if (hydrationElement) {
        this.items = collectPropsFromElement(hydrationElement);
      } else {
        const result = await getCart();
        this.items = result.cart;
      }
    },
    list() {
      return Object.values(this.items);
    },
    get(id: number) {
      return this.items[id] ?? null;
    },
    update(newCart: Cart) {
      this.items = { ...newCart };
    },
    async add(id: number, { ecommerceList = null } = {}) {
      showToast('Товар добавлен в корзину', 'face-smile');
      const data = await addProductToCart(id, { ecommerceList });
      this.update(data.cart);

      ecommerceAddProducts([cartItemToEcommerceProduct(data.addedItem)], ecommerceList);
      vkSendAddToCart();
      addLifetimeCartAddition(id).then();
    },
    async remove(id: number) {
      if (!(id in this.items)) return;
      showToast('Товар удален из корзины', 'face-sad');

      const item = this.get(id);
      ecommerceRemoveProducts([item]);

      // delete won't trigger update
      this.items = omit(this.items, id.toString());

      const data = await removeProductFromCart(id);
      this.update(data.cart);
    },
    async setQuantity(id: number, quantity: number) {
      if (!(id in this.items)) return;
      const item = this.get(id);

      if (quantity < item.minOrderQuantity) {
        await this.remove(id);
        return;
      }

      let ecommerceProduct = cartItemToEcommerceProduct(item);
      const delta = quantity - item.quantity;
      ecommerceProduct.quantity = Math.abs(delta);
      if (delta > 0) {
        ecommerceAddProducts([ecommerceProduct], item.ecommerceList);
        vkSendAddToCart();
      } else if (delta < 0) {
        ecommerceRemoveProducts([item]);
      }

      this.items[id].quantity = quantity;
      const data = await setCartProductQuantity(id, quantity);
      this.update(data.cart);
    },
    async clear() {
      ecommerceRemoveProducts(this.list());
      this.items = {};
      await clearCart();
      showToast('Корзина очищена', 'face-sad');
    },
  });
}
