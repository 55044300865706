import { timeout } from '../lib/timeout';

export function dropdown(Alpine) {
  Alpine.data('dropdown', dropdownComponent);

  function dropdownComponent() {
    return {
      isOpen: false,
      isDelay: false, // mouseover and click race condition delay
      init() {},
      async openDropdown() {
        this.isOpen = true;
        this.isDelay = true;
        await timeout(50);
        this.isDelay = false;
      },
      closeDropdown() {
        this.isOpen = false;
      },
      toggleDropdown() {
        if (this.isDelay) return;

        this.isOpen = !this.isOpen;
      },
    };
  }
}
