import Swiper, { Grid, Navigation, Pagination } from 'swiper';

function initSlider(element) {
  new Swiper(element.querySelector('.swiper'), {
    modules: [Pagination, Navigation, Grid],
    slidesPerView: 4,
    grid: {
      fill: 'row',
      rows: 2,
    },
    spaceBetween: 24,
    navigation: {
      nextEl: element.querySelector('.slider__next'),
      prevEl: element.querySelector('.slider__prev'),
    },
    pagination: {
      el: element.querySelector('.swiper-pagination'),
      clickable: true,
      dynamicBullets: true
    },
  });
}

document.querySelectorAll('.js-partners-slider').forEach(initSlider);
