import { CartItem } from '../lib/cart';
import { ecommerceCheckoutComplete } from '../lib/enhancedEcommerce';
import { vkSendPurchase } from '../lib/vkPixel';

type CheckoutSuccessParams = {
  order: { id: string; totalPrice: number; deliveryPrice: number; isFinished: boolean };
  cartItems: CartItem[];
};

export function checkoutSuccess(Alpine) {
  Alpine.data('checkoutSuccess', checkoutSuccessComponent);

  function checkoutSuccessComponent({ order, cartItems }: CheckoutSuccessParams) {
    return {
      init() {
        if (!order.isFinished) {
          ecommerceCheckoutComplete(order.id, order.totalPrice, order.deliveryPrice, cartItems);
          vkSendPurchase();
          this.$store.checkout.reset();
        }
      },
    };
  }
}
