export function toTop(Alpine) {
  Alpine.data('toTop', toTopComponent);

  function toTopComponent({ showOffset = 200 } = {}) {
    return {
      isShown: false,
      init() {
        this.$nextTick(() => {
          // will cause reflow, so postpone on next tick
          this.isShown = window.scrollY > showOffset;
        });
      },
      scrollToTop() {
        window.scroll(0, 0);
      },
      handleScroll() {
        this.isShown = window.scrollY > showOffset;
      },
    };
  }
}
