import Swiper, { FreeMode } from 'swiper';
import { loadLightgallery } from '../lib/loadLightgallery';

export function reviewCard(Alpine) {
  Alpine.data('reviewCard', reviewCardComponent);

  function reviewCardComponent() {
    return {
      swiper: null,
      lightGallery: null,
      init() {
        loadLightgallery().then(({ default: lightGallery }) => {
          this.lightGallery = lightGallery(this.$refs.swiper, {
            speed: 500,
            licenseKey: '0000-0000-000-0000',
            selector: '.reviews-card__link',
            counter: false,
            download: false,
          });
        });

        if (this.$refs.swiper) {
          this.swiper = new Swiper(this.$refs.swiper, {
            modules: [FreeMode],
            direction: 'horizontal',
            freeMode: true,
            slidesPerView: 'auto',
            spaceBetween: 8,
            mousewheel: true,
          });
        }
      },
      destroy() {
        if (this.swiper) this.swiper.destroy();
        if (this.lightGallery) this.lightGallery.destroy();
      },
    };
  }
}
