import ky from 'ky';
import { WpRequestError } from './errors';
import { requestTimeout } from '../variables';
import * as Sentry from '@sentry/browser';

type WpAjaxResponse = {
  success: boolean;
  data: any;
};

/**
 * Loads data from WP Ajax action handler.
 *
 * NOTE: For add_action( 'wp_ajax_myaction', $ajax_object_query_handler );
 * 'myaction' will be the action name.
 *
 * @param {string} action Wordpress ajax action name
 * @param {object} params Other params passed to handler as $_POST['param_key']
 * @param files Files to send
 * @returns {Promise<object>} Returned text data in JSON format
 */
export async function wpAjaxRequest(action: string, params: object = {}, files = []) {
  const formData = new FormData();
  for (const key in params) {
    formData.append(key, params[key]);
  }

  files.forEach((file) => formData.append('file', file));

  formData.append('action', action);

  let body, res;
  try {
    res = await ky.post(globalThis.wpParams.ajaxurl, {
      timeout: requestTimeout,
      body: formData,
    });
    body = (await res.json()) as WpAjaxResponse;
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'Ajax',
        method: 'POST',
      },
      extra: {
        action,
        params,
      },
    });
    return null;
  }

  if (!res.ok) {
    if (body?.data?.errorMessage) {
      throw new WpRequestError(body.data.errorMessage, res);
    } else {
      throw new WpRequestError(res.statusText, res);
    }
  }
  return body.data;
}
