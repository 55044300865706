import Swiper, { FreeMode } from 'swiper';

export function mobileHorizontalScroller(Alpine) {
  Alpine.data('mobileHorizontalScroller', mobileHorizontalScrollerComponent);

  function mobileHorizontalScrollerComponent({ disableFrom = 768 } = {}) {
    return {
      swiper: null,
      init() {
        this.swiper = new Swiper(this.$root, {
          modules: [FreeMode],
          direction: 'horizontal',
          freeMode: true,
          slidesPerView: 'auto',
          mousewheel: true,
          breakpoints: {
            [disableFrom]: {
              enabled: false,
            },
          },
        });
      },
      destroy() {
        if (this.swiper) this.swiper.destroy();
      },
    };
  }
}
