import { wpRESTGet } from '../lib/wpREST';
import { toPHPQueryString } from '../lib/queryString';
import { Product } from '../lib/product';

export type CatalogPageRequestParams = {
  categoryId?: number;
  page?: number;
  current_page_url: string;
  filter_search?: string;
  from?: string;
  to?: string;
  sorting?: string;
};

type CatalogPageHtml = {
  products: string;
  filterText: string;
  pagination: string;
};

type CatalogFilter = { [key: string]: string | number | boolean };

type CatalogPageResponse = {
  html: CatalogPageHtml;
  totalPages: number;
  totalProducts: number;
  filter: CatalogFilter;
  minPrice: number;
  maxPrice: number;
};

export type ProductReadResponse = Product;
export type ProductReadField = 'id' | 'bundleComponents';

export async function getCatalogPage(
  params: CatalogPageRequestParams
): Promise<CatalogPageResponse> {
  const queryString = toPHPQueryString(params);
  return wpRESTGet<CatalogPageResponse>(`nf/v1/products/?${queryString}`);
}

export async function getProduct(
  id: number,
  fields: ProductReadField[]
): Promise<ProductReadResponse> {
  const params = { fields };
  const queryString = toPHPQueryString(params);
  return wpRESTGet<ProductReadResponse>(`nf/v1/products/${id}?${queryString}`);
}
