import { formatPrice, pluralize } from './lib/helpers';
import { imgproxyImageURI } from './lib/imgproxy';
import { filesize } from 'filesize';

export function directives(Alpine) {
  Alpine.directive('price', (el: HTMLElement, { expression }, { evaluateLater, effect }) => {
    const getPrice = evaluateLater(expression);

    effect(() => {
      getPrice((price: number | string) => {
        if (typeof price === 'string') {
          price = parseFloat(price.replace(/\D/g, ''));
        }

        el.textContent = formatPrice(price);
      });
    });
  });

  Alpine.directive(
    'pluralized-text',
    (el: HTMLElement, { modifiers, expression }, { evaluateLater, effect }) => {
      const getNumber = evaluateLater(expression);

      effect(() => {
        getNumber((number) => {
          el.textContent = pluralize(number, modifiers[0], modifiers[1], modifiers[2]);
        });
      });
    }
  );

  Alpine.directive('filesize', (el: HTMLElement, { expression }, { evaluateLater, effect }) => {
    const getValue = evaluateLater(expression);

    effect(() => {
      getValue((value: number | string) => {
        if (typeof value === 'string') {
          value = parseFloat(value);
        }

        el.textContent = filesize(value, { standard: 'jedec' });
      });
    });
  });

  Alpine.directive(
    'imgproxy-src',
    (el: HTMLImageElement, { modifiers, expression }, { evaluateLater, effect }) => {
      const getImgURI = evaluateLater(expression);
      const imgproxy = imgproxyImageURI(wpParams.imageproxyURI);

      effect(() => {
        getImgURI((imgURI) => {
          const [processingOptions, format] = modifiers;
          el.src = imgproxy(imgURI, processingOptions, format);
        });
      });
    }
  );

  Alpine.directive(
    'imgproxy-srcset',
    (
      el: HTMLImageElement | HTMLSourceElement,
      { modifiers, expression },
      { evaluateLater, effect }
    ) => {
      const getImgURI = evaluateLater(expression);
      const imgproxy = imgproxyImageURI(wpParams.imageproxyURI);

      effect(() => {
        getImgURI((imgURI) => {
          const list = [];
          for (let groupIdx = 0; groupIdx < modifiers.length; groupIdx += 3) {
            const processingOptions = modifiers[groupIdx];
            const format = modifiers[groupIdx + 1];
            const descriptor = modifiers[groupIdx + 2];
            list.push(`${imgproxy(imgURI, processingOptions, format)} ${descriptor}`);
          }
          el.srcset = list.join(', ');
        });
      });
    }
  );
}
