import { wpRESTGet, wpRESTPost } from '../lib/wpREST';

type ReviewsResponse = {
  total: number;
  htmlItems: string[];
};

type CreateReviewResponse = {};

export async function getReviews(
  page: number = 1,
  pageSize: number = 10,
  skipIds: number[] = []
): Promise<ReviewsResponse> {
  let params = { page, pageSize };
  if (skipIds) params['skipIds'] = skipIds.join(',');

  return wpRESTGet<ReviewsResponse>('nf/v1/reviews/', params);
}

export async function createReview(
  name: string,
  honeypot: string,
  text: string,
  rating: number
): Promise<CreateReviewResponse> {
  return wpRESTPost<CreateReviewResponse>('nf/v1/reviews/', { name, honeypot, text, rating });
}
