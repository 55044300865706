import Swiper, { Navigation } from 'swiper';
import { md } from '../variables';

export function productsSliderSlim(Alpine) {
  Alpine.data('productsSliderSlim', productsSliderSlimComponent);

  function productsSliderSlimComponent() {
    return {
      swiper: null,
      init() {
        this.swiper = new Swiper(this.$refs.swiper, {
          modules: [Navigation],
          slidesPerView: 'auto',
          spaceBetween: 8,
          navigation: {
            nextEl: this.$refs.next,
            prevEl: this.$refs.prev,
          },
          breakpoints: {
            [md]: {
              slidesPerView: 4,
            },
          },
        });
      },
      destroy() {
        this.swiper?.destroy();
      },
    };
  }
}
