import {addFavorite, getFavorites, removeFavorite} from '../api/favorites';
import {showToast} from '../components/toast';

export function favoritesStore(Alpine) {
  Alpine.store('favorites', {
    items: [],
    get count() {
      return this.items.length;
    },
    async init() {
      this.items = await getFavorites();
    },
    has(id: number) {
      return this.items.includes(id);
    },
    async add(id: number) {
      this.items.push(id);

      try {
        this.items = await addFavorite(id);
        showToast('Товар добавлен в избранное', 'face-smile');
      } catch (e) {
      }
    },
    async remove(id: number) {
      this.items = this.items.filter((item) => item !== id);

      try {
        this.items = await removeFavorite(id);
        showToast('Товар удален из избранного', 'face-sad');
      } catch (e) {
      }
    },
    async toggle(id: number) {
      if (!this.has(id)) {
        await this.add(id);
      } else {
        await this.remove(id);
      }
    },
    async clear() {
      this.items = [];
      try {
        this.items = await removeFavorite(0);
        showToast('Товар удален из избранного', 'face-sad');
        showToast('Избранное очищено', 'face-sad');
      } catch (e) {
      }
    }
  });
}
