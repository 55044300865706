import { incrementViews } from '../api/blog';
import { timeout } from '../lib/timeout';

export function blogPost(Alpine) {
  Alpine.data('blogPost', blogPostComponent);

  function blogPostComponent({ id }) {
    return {
      async init() {
        await timeout(2000);
        await incrementViews(id);
      },
    };
  }
}
