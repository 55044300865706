export function pluralize(value: number, one: string, two: string, five: string): string {
  value = Math.abs(value) % 100;
  const num = value % 10;

  if (value > 10 && value < 20) return five;
  if (num > 1 && num < 5) return two;
  if (num == 1) return one;
  return five;
}

export function formatPrice(price: number, { withoutSymbol = false } = {}): string {
  let val = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price);

  if (withoutSymbol) val = val.replace(/[^\d\s,]/g, '').trim();

  return val;
}
