import { dynamicImportWithRetry } from './dynamicImportWithRetry';

export async function loadFlatpickr() {
  // @ts-ignore
  dynamicImportWithRetry(() => import('../../styles/flatpickr.scss')).then();

  const loaded = await Promise.all([
    dynamicImportWithRetry(() => import('flatpickr')),
    dynamicImportWithRetry(() => import('flatpickr/dist/l10n/ru.js'))
  ])

  return {
    flatpickr: loaded[0].default,
    Russian: loaded[1].Russian,
  }
}
