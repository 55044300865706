export function imageUploader(Alpine) {
  Alpine.data('imageUploader', imageUploaderComponent);

  function imageUploaderComponent() {
    return {
      dataURLs: [],
      init() {},
      handleFileChange(e) {
        const files = [...e.target.files];

        files.forEach((file, i) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.dataURLs[i] = e.target.result;
          };
          reader.readAsDataURL(file);
        });
      },
    };
  }
}
