export type DeliveryZone = {
  name: string;
  expressHours: number;
  expressPrice: number;
};

export const deliveryZones: DeliveryZone[] = [
  { name: 'Зона 1', expressHours: 2, expressPrice: 500 },
  { name: 'Зона 2', expressHours: 3, expressPrice: 500 },
  { name: 'Зона 3', expressHours: 4, expressPrice: 900 },
  { name: 'Зона 4', expressHours: 6, expressPrice: 1500 },
  { name: 'Зона 5', expressHours: 6, expressPrice: 1500 },
];
