// https://github.com/zouhir/preact-habitat/

/**
 * Removes `-` from a string and capitalize the letter after
 * example: data-props-hello-world =>  dataPropsHelloWorld
 * Used for props passed from host DOM element
 *
 * @param  {String} str string
 * @return {String} Capitalized string
 */
function camelcasize(str: string) {
	return str.replace(/-([a-z])/gi, (all, letter) => {
		return letter.toUpperCase();
	});
}

/**
 * Get the props from an element's data attributes or
 * embedded <script type="text/json"> tags.
 *
 * @param  {Element} element The host element
 * @param defaultProps
 * @return {Object}  props object to be passed to the component
 */
export function collectPropsFromElement(element: Element, defaultProps = {}) {
	let attrs = element.attributes;
	let props = Object.assign({}, defaultProps);

	// collect from element
	Object.keys(attrs).forEach((key: string) => {
		if (attrs.hasOwnProperty(key)) {
			// @ts-ignore
			const attr: Attr = attrs[key];
			let dataAttrName = attr.name;
			if (!dataAttrName || typeof dataAttrName !== "string") {
				return false;
			}
			let propName = dataAttrName.split(/(data-props?-)/).pop() || "";
			propName = camelcasize(propName);
			if (dataAttrName !== propName) {
				let propValue = attr.nodeValue;
				// @ts-ignore
				props[propName] = propValue;
			}
		}
	});

	const collectFromScript = (scrp: Element) => {
		let propsObj = {};
		if (scrp.hasAttribute("type")) {
			if (
				scrp.getAttribute("type") !== "text/props" &&
				scrp.getAttribute("type") !== "application/json" &&
				scrp.getAttribute("type") !== "application/ld+json"
			)
				return;
			try {
				propsObj = JSON.parse(scrp.innerHTML);
			} catch (e) {
				throw new Error(e);
			}
			Object.assign(props, propsObj);
		}
	};

	if (element.tagName === "SCRIPT") {
		collectFromScript(element);
	} else {
		// check for child script text/props or application/json
		[].forEach.call(
			element.getElementsByTagName("script"),
			collectFromScript
		);
	}

	return props;
}
