import { getYmaps } from './services';

class DeliveryMap {
  private mapElement: any;
  private options: { coordinates: number[]; onLocationChange: (lat, lng) => void };
  private geojson: any;
  private map: ymaps.Map;
  private pin: ymaps.Placemark;

  constructor(mapElement, geojson, options = {}) {
    this.mapElement = mapElement;

    const defaults = {
      onLocationChange: (lat, lng) => {},
      coordinates: [0, 0],
    };
    this.options = { ...defaults, ...options };

    this.geojson = geojson;

    this.init = this.init.bind(this);
    this.updateCoordinates = this.updateCoordinates.bind(this);
    getYmaps().then(this.init);
  }

  init(ymaps) {
    this.map = new ymaps.Map(
      this.mapElement,
      {
        center: [59.97807136118649, 30.347746962950627],
        zoom: 9,
        controls: [],
      },
      {
        suppressMapOpenBlock: true,
      }
    );
    const zoomControl = new ymaps.control.ZoomControl({
      options: {
        size: 'small',
      },
    });
    this.map.controls.add(zoomControl);

    this.pin = new ymaps.Placemark(
      [0, 0],
      {},
      {
        preset: 'islands#redStretchyIcon',
        draggable: true,
      }
    );
    //this.pin.options.set('visible', true);
    this.pin.events.add('dragend', (event) => {
      this.updateCoordinates(this.pin.geometry.getCoordinates());
    });
    this.map.geoObjects.add(this.pin);

    this.map.events.add('click', (event) => {
      const coords = event.get('coords');
      this.updateCoordinates(coords);
    });

    const geolocationControl = new ymaps.control.GeolocationControl({
      options: { noPlacemark: true },
    });
    geolocationControl.events.add('locationchange', (event) => {
      const position = event.get('position');
      this.updateCoordinates(position, true);
    });
    this.map.controls.add(geolocationControl);

    if (this.options.coordinates[0] !== 0 && this.options.coordinates[1] !== 0) {
      this.updateCoordinates(this.options.coordinates, true);
    }

    // zones
    if (this.geojson && this.geojson.features) {
      this.geojson.features.forEach((feature) => {
        if (feature.geometry.type !== 'Polygon') return;
        const coordinates = feature.geometry.coordinates.map((polygon) =>
          polygon.map((vertex) => [vertex[1], vertex[0]])
        );

        const zone = new ymaps.GeoObject(
          {
            geometry: {
              type: 'Polygon',
              coordinates: coordinates,
            },
            properties: {},
          },
          {
            fillColor: feature.properties.fill,
            strokeColor: feature.properties.stroke,
            fillOpacity: 0.05,
            strokeWidth: 1,
            interactivityModel: 'default#transparent',
          }
        );
        this.map.geoObjects.add(zone);
      });
    }
  }

  updateCoordinates(coordinates, center = false, zoom = 15) {
    const oldCoordinates = this.pin.geometry.getCoordinates();
    const [lat, lon] = coordinates;

    if (oldCoordinates[0] === coordinates[0] && oldCoordinates[1] === coordinates[1]) {
      return;
    }

    if (lat === 0 && lon === 0) {
      this.map.setCenter([59.97807136118649, 30.347746962950627], 9);
      this.pin.geometry.setCoordinates(coordinates);
      return;
    }

    this.pin.geometry.setCoordinates(coordinates);

    if (center) {
      this.map.setCenter(coordinates, zoom);
    }

    if (this.options.onLocationChange instanceof Function) {
      this.options.onLocationChange(coordinates[0], coordinates[1]);
    }
  }
}

export { DeliveryMap };
