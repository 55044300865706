import { timeout } from '../lib/timeout';
import { createReview } from '../api/reviews';

export function addReviewDialog(Alpine) {
  Alpine.data('addReviewDialog', addReviewDialogComponent);

  function addReviewDialogComponent() {
    return {
      isOpen: false,
      isSubmitting: false,
      hasSubmitted: false,
      hasFailed: false,
      rating: 0,
      name: '',
      phone: '', // honeypot
      text: '',
      photos: [],
      get isValid() {
        return this.name.length > 0 && this.text.length > 0 && this.rating > 0;
      },
      init() {
        document.querySelectorAll('[data-open-add-review]').forEach((el) => {
          el.addEventListener('click', () => this.open());
        });
      },
      open() {
        this.isOpen = true;
      },
      async close() {
        this.isOpen = false;
        await timeout(300);
        this.hasSubmitted = false;
        this.hasFailed = false;
      },
      reset() {
        this.rating = 0;
        this.name = '';
        this.text = '';
      },
      cancel() {
        this.reset();
        this.close();
      },
      async onSubmit() {
        this.isSubmitting = true;
        try {
          await createReview(this.name, this.phone, this.text, this.rating);
          this.hasSubmitted = true;
          await timeout(7000);
          if (this.isOpen) {
            await this.close();
          }
        } catch (e) {
          this.hasFailed = true;
        }
        this.isSubmitting = false;
        this.reset();
      },
    };
  }
}
