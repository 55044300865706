import get from 'just-safe-get';

export function input(Alpine) {
  Alpine.data('input', inputComponent);

  function inputComponent({
    initialValue = '',
    model = 'value',
    errorParam = 'errorMessage',
    maxLength = 0,
  } = {}) {
    return {
      value: initialValue,
      isInputFocused: false,
      errorMessage: '',
      maxLength,
      get isEmpty() {
        const value = get(this, model, '');
        return value.toString().length === 0;
      },
      get isFocused() {
        return this.isInputFocused || !this.isEmpty;
      },
      get error() {
        return get(this, errorParam, '');
      },
      get length() {
        return this.value.length;
      },
      label: {
        [':class']() {
          return {
            'is-focused': this.isFocused,
            'has-error': this.error,
          };
        },
      },
      input: {
        'x-model': 'value',
        ['@focus']() {
          this.isInputFocused = true;
        },
        ['@blur']() {
          this.isInputFocused = false;
        },
      },
    };
  }
}
