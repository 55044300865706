import { timeout } from '../lib/timeout';

type Emoji = 'face-smile' | 'face-sad';

export function toast(Alpine) {
  Alpine.data('toast', toastComponent);

  function toastComponent() {
    return {
      text: '',
      emoji: null,
      isShown: false,
      timeout: null,
      async close() {
        this.isShown = false;
        await timeout(200); // wait for hide animation to finish
        this.text = '';
        this.emoji = null;
      },
      show(text, emoji?: Emoji, duration = 2000) {
        this.isShown = true;
        this.text = text;
        this.emoji = emoji;

        if (this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = null;
        }

        this.timeout = setTimeout(() => this.close(), duration);
      },
    };
  }
}

export function showToast(text: string, emoji: Emoji = null) {
  document.dispatchEvent(
    new CustomEvent('show-toast', {
      detail: { text, emoji },
      bubbles: true,
      // Allows events to pass the shadow DOM barrier.
      composed: true,
      cancelable: true,
    })
  );
}
