import get from 'just-safe-get';
import enquire from 'enquire.js';
import { DeliveryInterval } from '../lib/deliveryTime';
import { md } from '../variables';
import { disableScroll, enableScroll } from '../lib/scroll';
import { formatPrice } from '../lib/helpers';
import { dynamicImportWithRetry } from '../lib/dynamicImportWithRetry';

type TimeSelectParams = {
  deliveryIntervals: string;
};

type TimeSelectOption = {
  value: string;
  text: string;
  price: number;
};

function formatTimeItem(item: TimeSelectOption, escape) {
  let html = `<div>${escape(item.text)}`;
  if (item.price > 0) {
    html += `,&nbsp;<span class="text-aux">+${escape(formatPrice(item.price))}</span>`;
  }
  html += '<div>';

  return html;
}

function intervals2options(intervals: DeliveryInterval[]) {
  return intervals.map((interval) => ({
    value: interval.value,
    text: interval.label,
    price: interval.price,
  }));
}

export function timeSelect(Alpine) {
  Alpine.data('timeSelect', timeSelectComponent);

  function timeSelectComponent({ deliveryIntervals }: TimeSelectParams) {
    return {
      isSelectFocused: false,
      tomSelect: null,
      value: '',
      error: '',
      get isEmpty() {
        return this.value.toString().length === 0;
      },
      get isFocused() {
        return this.isSelectFocused || !this.isEmpty;
      },
      async init() {
        await this.$nextTick();
        const TomSelect = await dynamicImportWithRetry(() => import('tom-select'));
        const label = this.$refs.label?.textContent;

        const intervals = get(this, deliveryIntervals, []);
        const options = intervals2options(intervals);
        const selected = this.value ? [this.value] : [];

        // @ts-ignore
        this.tomSelect = new TomSelect(this.$refs.select, {
          controlInput: null,
          copyClassesToDropdown: false,
          wrapperClass: 'select__wrapper',
          controlClass: 'select__control',
          dropdownClass: 'select__dropdown',
          dropdownContentClass: 'select__dropdown-content',
          itemClass: 'select__item',
          optionClass: 'select__option',
          options: options,
          items: selected,
          maxOptions: 999,
          render: {
            item: formatTimeItem,
            option: formatTimeItem,
            dropdown: () => `<div><div class="select__dropdown-heading">${label}</div></div>`,
          },
        });

        this.tomSelect.on('focus', () => (this.isSelectFocused = true));
        this.tomSelect.on('blur', () => (this.isSelectFocused = false));

        this.tomSelect.on('dropdown_open', () => {
          if (window.innerWidth < md) {
            disableScroll();
            this.isScrollPrevented = true;
          }
        });
        this.tomSelect.on('dropdown_close', () => {
          if (this.isScrollPrevented) {
            enableScroll();
            this.isScrollPrevented = false;
          }
        });

        enquire.register(`screen and (min-width: ${md}px)`, {
          match: () => {
            if (this.isScrollPrevented) {
              enableScroll();
              this.isScrollPrevented = false;
            }
          },
        });

        // populate options list
        this.$watch(deliveryIntervals, () => {
          this.updateTimeOptions();
        });

        // update value on select change
        this.tomSelect.on('change', (value) => {
          this.value = value;
        });

        // update select on value change
        this.$watch('value', (newValue) => {
          this.tomSelect.addItem(newValue, true);
        });

        this.tomSelect.addItem(this.value, true);
      },

      updateTimeOptions() {
        this.tomSelect.clear();
        this.tomSelect.clearOptions();

        const intervals = get(this, deliveryIntervals, []);
        const options = intervals2options(intervals);
        this.tomSelect.addOptions(options);
      },

      close() {
        if (this.isSelectFocused) {
          this.tomSelect.blur();
          this.tomSelect.close();
        }
      }
    };
  }
}
