export function headerPanel(Alpine) {
  function headerPanelComponent() {
    return {
      detached: false,
      _shown: false,
      init() {
        const container = this.$refs.container;

        let lastScrollPos = 0;

        const update = () => {
          const scrollPos = window.scrollY;
          const { top, bottom } = container.getBoundingClientRect();

          const isBottomReached = bottom <= 0;
          const isTopReached = !isBottomReached && top >= 0;
          const isScrollingDown = scrollPos > lastScrollPos;

          if (isBottomReached) this.detached = true;
          if (isTopReached) this.detached = false;

          this.$store.header._isShown = this.detached && !isScrollingDown;

          lastScrollPos = scrollPos;
        };
        update();

        document.addEventListener('scroll', update);
      },
    };
  }

  Alpine.data('headerPanel', headerPanelComponent);
}
