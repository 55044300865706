declare type Ymaps = typeof import('yandex-maps');
export type { Ymaps };

let init = false;
let ymaps: Ymaps;
let err;

/**
 * Asynchronously loads ymaps script
 */
export function ymapsAsyncLoader(apiKey: string): Promise<Ymaps> {
  return new Promise((resolve, reject) => {
    if (!init) {
      const el = document.createElement('script');
      el.src =
        'https://api-maps.yandex.ru/2.1/?lang=ru_RU' +
        '&onload=ymapsLoaderOnLoad' +
        '&onerror=ymapsLoaderOnError' +
        '&apikey=' +
        apiKey;
      el.defer = true;
      document.body.appendChild(el);
      init = true;
    }

    if (ymaps) {
      resolve(ymaps);
      return;
    }

    document.addEventListener('ymapsLoaderOnLoad', () => {
      resolve(ymaps);
    });

    document.addEventListener('ymapsLoaderOnError', () => {
      reject(new Error(err.message));
    });
  });
}

window.ymapsLoaderOnLoad = (resultYmaps) => {
  ymaps = resultYmaps;

  const event = document.createEvent('Event');
  event.initEvent('ymapsLoaderOnLoad', false, false);
  document.dispatchEvent(event);
};

window.ymapsLoaderOnError = (resultErr) => {
  err = resultErr;

  const event = document.createEvent('Event');
  event.initEvent('ymapsLoaderOnError', false, false);
  document.dispatchEvent(event);
};
