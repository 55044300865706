import { wpRESTPost } from '../lib/wpREST';

type LifetimeViews = number;
type LifetimeCartAdditions = number;

export async function addLifetimeView(productId: number): Promise<LifetimeViews> {
  return wpRESTPost<LifetimeViews>(`nf/v1/products/${productId}/add_lifetime_view/`);
}

export async function addLifetimeCartAddition(productId: number): Promise<LifetimeCartAdditions> {
  return wpRESTPost<LifetimeCartAdditions>(
    `nf/v1/products/${productId}/add_lifetime_cart_addition/`
  );
}
