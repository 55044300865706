export function onPageLoad(): Promise<void> {
  return new Promise<void>((resolve) => {
    if (document.readyState === 'complete') return resolve();
    window.addEventListener('load', () => resolve());
  });
}

export function onIdle(timeout: number = undefined) {
  return new Promise<void>((resolve) => {
    if (!('requestIdleCallback' in window)) resolve();
    requestIdleCallback(() => resolve(), { timeout });
  });
}
