function handleOpen(detail, selector) {
  if (!detail.open) return;

  const siblings = detail.parentNode.querySelectorAll(selector);
  siblings.forEach((sibling) => {
    if (sibling === detail) return;
    sibling.removeAttribute('open');
  });
}

function detailsGroup(selector:string) {
  const details = document.querySelectorAll(selector);
  details.forEach((detail) =>
    detail.addEventListener('toggle', (event) =>
      handleOpen(event.target, selector)
    )
  );
}

detailsGroup('.details-group details');
