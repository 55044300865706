export type ImgFormat = 'jpg' | 'png' | 'wepb' | 'avif';

export function imgproxyImageURI(imgproxyServerURI: string) {
  return (origImageURI: string, processingOptions: string, format: ImgFormat) => {
    if (processingOptions) {
      processingOptions += '/';
    }

    let uri = `${imgproxyServerURI}/path/${processingOptions}plain/${origImageURI}`;
    if (format) {
      uri += `@${format}`;
    }

    return uri;
  };
}
