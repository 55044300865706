import Swiper, { Pagination, Thumbs, FreeMode, Navigation, Mousewheel } from 'swiper';
import { loadPlyr } from '../lib/loadPlyr';

export function galleryDialog(Alpine) {
  Alpine.data('galleryDialog', galleryDialogComponent);

  function galleryDialogComponent({ id, isOpen }) {
    return {
      isOpen: isOpen,
      swiper: null,
      thumbnailSwiper: null,
      init() {
        this.initOpenTriggers();
      },
      open(slideIndex = null) {
        this.isOpen = true;
        this.$nextTick(() => {
          this.initSwipers(slideIndex);
          // this.initPlyr();
        });
      },
      close() {
        this.isOpen = false;

        if (this.swiper) this.swiper.destroy();
        if (this.thumbnailSwiper) this.thumbnailSwiper.destroy();
      },
      initSwipers(slideIndex) {
        this.thumbnailSwiper = new Swiper(this.$refs.thumbnailSwiper, {
          modules: [FreeMode, Mousewheel, Navigation],
          slidesPerView: 'auto',
          spaceBetween: 8,
          watchSlidesProgress: true,
          mousewheel: true,
          freeMode: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });

        this.swiper = new Swiper(this.$refs.swiper, {
          modules: [Pagination, Thumbs, Navigation],
          slidesPerView: 1,
          spaceBetween: 16,
          initialSlide: slideIndex,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          thumbs: {
            swiper: this.thumbnailSwiper,
          },
        });
      },
      initPlyr() {
        const videos = this.$root.querySelectorAll('.plyr');
        if (videos) {
          loadPlyr().then((Plyr) => {
            videos.forEach((video) => {
              // @ts-ignore
              new Plyr(video, {});
            });
          });
        }
      },
      initOpenTriggers() {
        document
          .querySelectorAll(`[data-gallery-dialog-open="${id}"]`)
          .forEach((item: HTMLElement) => {
            //if ('isOpen' in item.dataset) this.open();
            const slideIndex =
              'galleryDialogSlide' in item.dataset
                ? parseInt(item.dataset.galleryDialogSlide, 10)
                : null;

            item.addEventListener('click', (e) => {
              e.preventDefault();
              this.open(slideIndex);
            });
          });
      },
    };
  }
}
