import { createValidator, ValidationChecks, ValidationRule } from '../lib/validation';

type PaymentForm = {
  price: number;
  phone: string;
};

type PaymentFormChecks = ValidationChecks<PaymentForm>;
type Rule = ValidationRule<PaymentForm>;

const exists = <T>(value: T) => !!value;

const validateBuyerPhonePresence: Rule = ({ phone }) => exists(phone);
const validatePrice: Rule = ({ price }) => exists(price) && price > 0;

const paymentFormChecks: PaymentFormChecks = {
  price: [
    {
      validate: validatePrice,
      error: 'Укажите сумму оплаты',
    },
  ],
  phone: [
    {
      validate: validateBuyerPhonePresence,
      error: 'Заполните телефон',
    },
  ],
};

const validateForm = createValidator(paymentFormChecks);

export function customPayment(Alpine) {
  Alpine.data('customPayment', customPaymentComponent);

  function customPaymentComponent() {
    return {
      price: '',
      errors: {},
      onSubmit(event) {
        const form: PaymentForm = {
          price: this.price,
          phone: this.$store.checkout.form.buyerPhone,
        };

        const validation = validateForm(form);
        if (validation.isValid) {
          event.target.submit();
        } else {
          this.errors = validation.errors;
        }
      },
    };
  }
}
