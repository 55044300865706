import get from 'just-safe-get';
import { dynamicImportWithRetry } from '../lib/dynamicImportWithRetry';
// import $ from 'jquery';
// // @ts-ignore
// window.jQuery = $;
// // @ts-ignore
// window.$ = $;
// import 'suggestions-jquery';

export function inputAddress(Alpine) {
  Alpine.data('inputAddress', inputAddressComponent);

  function inputAddressComponent({
    initialValue = '',
    model = 'value',
    errorParam = 'errorMessage',
  } = {}) {
    return {
      value: initialValue,
      isInputFocused: false,
      errorMessage: '',
      get isEmpty() {
        const value = get(this, model, '');
        return value.toString().length === 0;
      },
      get isFocused() {
        return this.isInputFocused || !this.isEmpty;
      },
      get error() {
        return get(this, errorParam, '');
      },
      label: {
        [':class']() {
          return {
            'is-focused': this.isFocused,
            'has-error': this.error,
          };
        },
      },
      input: {
        'x-model.lazy': model,
        ['@focus']() {
          this.isInputFocused = true;
        },
        ['@blur']() {
          this.isInputFocused = false;
        },
      },
      async init() {
        const $ = await dynamicImportWithRetry(() => import('jquery'));
        // @ts-ignore
        window.jQuery = $;
        // @ts-ignore
        window.$ = $;
        await dynamicImportWithRetry(() => import('suggestions-jquery'));

        $(this.$refs.input).suggestions({
          token: wpParams.dadataToken,
          type: 'ADDRESS',
          hint: null,
          count: 6,
          onSelect: (suggestion) => {
            this.$refs.input._x_model.set(suggestion.value);
          },
        });
      },
    };
  }
}
