import Swiper, { Navigation } from 'swiper';
import { md, sm } from '../variables';

export function productSlider(Alpine) {
  Alpine.data('productSlider', productSliderComponent);

  function productSliderComponent(isLazy = true) {
    return {
      swiper: null,
      init() {
      },
      initSwiper() {
        if (!this.$refs.swiper) return;

        this.swiper = new Swiper(this.$refs.swiper, {
            modules: [Navigation],
            watchSlidesProgress: true,
            preloadImages: false,
            slidesPerView: 'auto',
            spaceBetween: 8,
            navigation: {
              nextEl: this.$refs.next,
              prevEl: this.$refs.prev,
            },
            breakpoints: {
              [sm]: {
                spaceBetween: 12,
              },
              [md]: {
                slidesPerView: 4,
                spaceBetween: 16,
              },
            },
          });
      },
      destroy() {
        this.swiper?.destroy();
      },
    };
  }
}
