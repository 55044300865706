import { wpAjaxRequest } from './lib/wpAjax';

export function submitFloristConsultForm(
  name: string,
  phone: string,
  honeypot: string,
  nonce: string
) {
  return wpAjaxRequest('submit_florist_consult_form', { name, phone, honeypot, nonce });
}
