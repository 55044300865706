import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import objectSupport from 'dayjs/plugin/objectSupport';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import { DeliveryZone } from './deliveryZones';
import plugin from 'dayjs/plugin/duration';

dayjs.extend(objectSupport);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(duration);

export type Time = {
  hour?: number;
  minute?: number;
};

export type TimeInterval = {
  from: Time;
  to: Time;
};

export type DeliveryInterval = {
  value: string;
  label: string;
  from?: Time;
  to?: Time;
  closed?: boolean;
  price?: number;
};

export const managersWorkingHours: TimeInterval = {
  from: { hour: 10, minute: 0 },
  to: { hour: 21, minute: 30 },
};

export function findDeliveryIntervalByValue(intervals: DeliveryInterval[], value: string) {
  return intervals.find((item) => item.value === value);
}

/**
 * Создает массив интервалов доставки.
 */
export function createDeliveryIntervals(
  from: Time,
  to: Time,
  duration: plugin.Duration = dayjs.duration({ minutes: 10 }),
  valueFormat: string = 'HH:mm',
  labelFormat: string = 'HH:mm'
): DeliveryInterval[] {
  let date = dayjs().set(from).date(1).second(0);
  let end = dayjs().set(to).date(1).second(0);
  if (end.isBefore(date)) end = end.date(2); // when to is < then from, assume it's the next day

  const intervals: DeliveryInterval[] = [];

  while (date.isBefore(end)) {
    const nextDate = date.add(duration);
    const interval = {
      value: date.format(valueFormat),
      label: date.format(labelFormat),
      from: { hour: date.hour(), minute: date.minute() },
      to: { hour: nextDate.hour(), minute: nextDate.minute() },
    };
    intervals.push(interval);

    date = nextDate;
  }

  return intervals;
}

export function applyZonePrices(
  intervals: DeliveryInterval[],
  zone: DeliveryZone,
  now: dayjs.Dayjs = dayjs()
) {
  const expressTime = now.add(zone.expressHours, 'hour');
  return intervals.map((interval) => {
    const max = now.set(interval.from);
    const basePrice = interval.price ?? 0;
    return max.isSameOrAfter(now) && max.isBefore(expressTime)
      ? { ...interval, price: basePrice + zone.expressPrice }
      : interval;
  });
}

/**
 * Проверяет, является ли выбранная дата сегодняшним днем.
 */
export function isToday(date: dayjs.Dayjs, now: dayjs.Dayjs = dayjs()) {
  return now.isSame(date, 'day');
}

/**
 * Возвращает ближайшую дату, на которую доступен заказ.
 */
export function getNearestAvailableDate(
  closingTime: Time,
  now: dayjs.Dayjs = dayjs()
): dayjs.Dayjs {
  const todayClosing = now.hour(closingTime.hour).minute(closingTime.minute);
  const startOfDay = now.hour(0).minute(0).second(0);
  const endOfDay = now.hour(23).minute(59).second(59);

  return now.isBetween(todayClosing, endOfDay, null, '[]') ? startOfDay.add(1, 'day') : startOfDay;
}

/**
 * Возвращает, могут ли сейчас менеджеры обработать заказ.
 */
export function canManagersProcessOrders(now: dayjs.Dayjs, workingHours: TimeInterval) {
  const closing = now.set(workingHours.to);
  const opening = now.set(workingHours.from);
  return !(now.isSameOrAfter(closing) || now.isBefore(opening));
}

/**
 * Возвращает оставшиеся не сегодня интервалы доставки.
 */
function remainingIntervals(now: dayjs.Dayjs, intervals: DeliveryInterval[]) {
  return intervals.filter(
    (interval) => !interval.from || now.set(interval.from).isSameOrAfter(now)
  );
}

/**
 * Возвращает интервалы доставки для времени, когда менеджер может принять заказ.
 */
function openedIntervals(intervals: DeliveryInterval[]) {
  return intervals.filter((interval) => !interval.closed);
}

/**
 * Возвращает доступные на выбранную дату интервалы доставки.
 *
 * now 23.01.2023 16:00, selected 23.01.2023 -> 18:00-22:00, 20:00-00:00
 * now 23.01.2023 22:00, selected 24.01.2023 -> 10:00-14:00...20:00-00:00
 * now 23.01.2023 16:00, selected 24.01.2023 -> 00:00-06:00...20:00-00:00
 */
export function getAvailableDeliveryTimeIntervals(
  now: dayjs.Dayjs,
  selectedDate: dayjs.Dayjs,
  intervals: DeliveryInterval[],
  workingHours: TimeInterval
) {
  const isTodaySelected = now.isSame(selectedDate, 'day');
  const isTomorrowSelected = now.add(1, 'day').isSame(selectedDate, 'day');
  const canProcess = canManagersProcessOrders(now, workingHours);
  const selectedDayIntervals = isTodaySelected ? remainingIntervals(now, intervals) : intervals;

  if (canProcess) {
    return selectedDayIntervals;
  }

  if (isTodaySelected || isTomorrowSelected) {
    return openedIntervals(selectedDayIntervals);
  }

  return intervals;
}
