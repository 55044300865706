import { timeout } from '../lib/timeout';
import { submitFloristConsultForm } from '../api';
import { createValidator, isPhone, ValidationChecks, ValidationRule } from '../lib/validation';
import { vkSendLead } from '../lib/vkPixel';
import { disableScroll, enableScroll } from '../lib/scroll';
import { exists, notExists } from '../lib/utils';

type FloristConsultForm = {
  name: string;
  phone: string;
  honeypot: string;
  nonce: string;
};

type FloristContactFormChecks = ValidationChecks<FloristConsultForm>;
type Rule = ValidationRule<FloristConsultForm>;

const validateName: Rule = ({ name }) => exists(name);
const validatePhonePresence: Rule = ({ phone }) => exists(phone);
const validatePhoneCorrectness: Rule = ({ phone }) => isPhone(phone);
const validateNonce: Rule = ({ nonce }) => exists(nonce);
const validateHoneypot: Rule = ({ honeypot }) => notExists(honeypot);

const paymentFormChecks: FloristContactFormChecks = {
  name: [
    {
      validate: validateName,
      error: 'Введите имя',
    },
  ],
  phone: [
    {
      validate: validatePhonePresence,
      error: 'Заполните телефон',
    },
    {
      validate: validatePhoneCorrectness,
      error: 'Введите телефон в формате +7 000 000-00-00',
    },
  ],
  nonce: [
    {
      validate: validateNonce,
      error: 'Bip boop',
    },
  ],
  honeypot: [
    {
      validate: validateHoneypot,
      error: 'Bip boop',
    },
  ],
};

const validateForm = createValidator(paymentFormChecks);

export function floristConsultDialog(Alpine) {
  Alpine.data('floristConsultDialog', floristConsultDialogComponent);

  function floristConsultDialogComponent({ nonce }) {
    return {
      isShown: false,
      isSubmitting: false,
      hasSubmitted: false,
      hasFailed: false,
      honeypot: '',
      errors: {},
      init() {
        this.$watch('isShown', (isShown) => {
          if (isShown) {
            disableScroll();
          } else {
            enableScroll();
          }
        });
      },
      open() {
        this.isShown = true;
      },
      async close() {
        this.isShown = false;
        await timeout(300);
        this.hasSubmitted = false;
        this.hasFailed = false;
        this.errors = {};
      },
      async onSubmit() {
        const form: FloristConsultForm = {
          name: this.$store.checkout.form.buyerName,
          phone: this.$store.checkout.form.buyerPhone,
          honeypot: this.honeypot,
          nonce,
        };
        const validation = validateForm(form);
        if (!validation.isValid) {
          this.errors = validation.errors;
          return;
        }

        this.isSubmitting = true;
        try {
          await submitFloristConsultForm(form.name, form.phone, form.honeypot, form.nonce);
          this.hasSubmitted = true;
          vkSendLead();
          await timeout(7000);
          if (this.isShown) {
            await this.close();
          }
        } catch (e) {
          this.hasFailed = true;
        }
        this.isSubmitting = false;
      },
    };
  }
}
