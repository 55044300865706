import Swiper from 'swiper';

document.addEventListener('DOMContentLoaded', (event) => {
  new Swiper('.category-cards__swiper', {
    enabled: true,
    slidesPerView: 'auto',
    spaceBetween: 8,
    breakpoints: {
      768: {
        enabled: true,
        spaceBetween: 12,
      },
      1024: {
        enabled: false,
        spaceBetween: 0,
      },
    },
  });
});
