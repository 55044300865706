export function isHttpUrl(url: string): boolean {
  let givenURL;
  try {
    givenURL = new URL(url);
  } catch (e) {
    return false;
  }

  return givenURL.protocol === 'http:' || givenURL.protocol === 'https:';
}
