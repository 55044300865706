import { initAnyQuery } from '../lib/anyQuery';

export function headerStore(Alpine) {
  Alpine.store('header', {
    _isShown: false,
    isCatalogOpen: false,
    isSearchOpen: false,

    get isHeaderShown() {
      return this._isShown || this.isCatalogOpen || this.isSearchOpen;
    },

    show() {
      this._isShown = true;
    },
    openCatalog() {
      this.isCatalogOpen = true;
      initAnyQuery();
    },
    openSearch() {
      this.isSearchOpen = true;
    },
  });
}
