import { getReviews } from '../api/reviews';

export function reviews(Alpine) {
  Alpine.data('reviews', reviewsComponent);

  function reviewsComponent({ total, shown, pageSize }) {
    return {
      total,
      shown,
      page: 1,
      get countRemaining() {
        return this.total - this.shown;
      },
      init() {},
      async loadMore() {
        this.page++;
        const moreReviews = await getReviews(this.page, pageSize);
        this.shown += moreReviews.htmlItems.length;
        if (this.$refs.list) {
          moreReviews.htmlItems.forEach((html) => {
            this.$refs.list.insertAdjacentHTML('beforeend', html);
          });
        }
      },
    };
  }
}
