export function dialog(Alpine) {
  Alpine.data('dialog', dialogComponent);

  function dialogComponent({ id }) {
    return {
      isOpen: false,
      init() {
        this.initOpenTriggers();
      },
      open() {
        this.isOpen = true;
      },
      close() {
        this.isOpen = false;
      },
      initOpenTriggers() {
        document.querySelectorAll(`[data-dialog-open="${id}"]`).forEach((item: HTMLElement) => {
          if ('isOpen' in item.dataset) this.open();

          item.addEventListener('click', (e) => {
            e.preventDefault();
            this.open();
          });
        });
      },
    };
  }
}
