import Swiper, { Navigation } from 'swiper';
import { md } from '../variables';

export function reviewsSlider(Alpine) {
  Alpine.data('reviewsSlider', reviewsSliderComponent);

  function reviewsSliderComponent({ isLazy = true } = {}) {
    return {
      swiper: null,
      loaded: !isLazy,
      init() {
        Alpine.effect(() => {
          if (!this.loaded) return;

          this.swiper = new Swiper(this.$refs.swiper, {
            modules: [Navigation],
            slidesPerView: 'auto',
            spaceBetween: 16,
            navigation: {
              nextEl: this.$refs.next,
              prevEl: this.$refs.prev,
            },
            breakpoints: {
              [md]: {
                slidesPerView: 3,
              },
            },
          });
        });
      },
      destroy() {
        this.swiper?.destroy();
      },
    };
  }
}
