import Swiper, { Navigation } from 'swiper';
import { md, sm } from '../variables';

type CardsSliderParams = {
  isLazy?: boolean;
};

export function cardsSlider(Alpine) {
  Alpine.data('cardsSlider', cardsSliderComponent);

  function cardsSliderComponent({ isLazy = true }: CardsSliderParams = {}) {
    return {
      swiper: null,
      loaded: false,
      init() {
        if (isLazy) {
          this.$watch('loaded', (loaded) => {
            if (!loaded || this.swiper) return;
            this.initSwiper();
          });
        } else {
          this.initSwiper();
        }
      },
      initSwiper() {
        this.swiper = new Swiper(this.$refs.swiper, {
          modules: [Navigation],
          slidesPerView: 'auto',
          spaceBetween: 8,
          navigation: {
            nextEl: this.$refs.next,
            prevEl: this.$refs.prev,
          },
          breakpoints: {
            [sm]: {
              spaceBetween: 12,
            },
            [md]: {
              slidesPerView: 3,
              spaceBetween: 16,
            },
          },
        });
      },
      load() {
        this.loaded = true;
      },
      destroy() {
        this.swiper?.destroy();
      },
    };
  }
}
