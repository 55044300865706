import {
  ecommerceAddToWishlist,
  EcommerceImpressionData,
  ecommerceSendDetails,
} from '../lib/enhancedEcommerce';
import { addLifetimeView } from '../api/product';

type ProductDetailsParams = {
  id: number;
  ecommerceProduct?: EcommerceImpressionData;
};

export function productDetails(Alpine) {
  Alpine.data('productDetails', productDetailsComponent);

  function productDetailsComponent({ id, ecommerceProduct = null }: ProductDetailsParams) {
    return {
      init() {
        if (ecommerceProduct) {
          ecommerceSendDetails(ecommerceProduct);
        }

        addLifetimeView(id).then();
      },
      async toggleFavorite() {
        if (!this.$store.favorites.has(id) && ecommerceProduct) {
          ecommerceAddToWishlist(ecommerceProduct);
        }

        await this.$store.favorites.toggle(id);
      },
    };
  }
}
