import { wpRESTDelete, wpRESTGet, wpRESTPost } from '../lib/wpREST';

type FavoritesResponse = number[];

export async function getFavorites(): Promise<FavoritesResponse> {
  return (await wpRESTGet<FavoritesResponse>('nf/v1/favorites/')) ?? [];
}

export function addFavorite(id: number): Promise<FavoritesResponse> {
  return wpRESTPost<FavoritesResponse>('nf/v1/favorites/', { id });
}

export function removeFavorite(id: number): Promise<FavoritesResponse> {
  return wpRESTDelete<FavoritesResponse>(`nf/v1/favorites/${id}`, {});
}
