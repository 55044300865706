/**
 * Search component stub that passes controls to AnyQuery.
 */
export function searchbarAnyQuery(Alpine) {
  Alpine.data('searchbarAnyQuery', searchbarAnyQueryComponent);

  function searchbarAnyQueryComponent() {
    return {
      async init() {
        // AnyQuery only waits for input focus to open.
        this.$watch('$store.header.isSearchOpen', (isOpen) => {
          if (isOpen) {
            this.$refs.input.focus();
          } else {
            this.$refs.input.blur();
          }
        });
      },
    };
  }
}
