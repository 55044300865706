import get from 'just-safe-get';
import flatpickr from 'flatpickr';
import { Russian } from 'flatpickr/dist/l10n/ru.js';

export function inputDate(Alpine) {
  Alpine.data('inputDate', inputDateComponent);

  function inputDateComponent({
    initialValue = '',
    model = 'value',
    errorParam = 'errorMessage',
  } = {}) {
    return {
      value: initialValue,
      isInputFocused: false,
      flatpickr: null,
      errorMessage: '',
      get isEmpty() {
        const value = get(this, model, '');
        return value.toString().length === 0;
      },
      get isFocused() {
        return this.isInputFocused || !this.isEmpty;
      },
      get error() {
        return get(this, errorParam, '');
      },
      label: {
        [':class']() {
          return {
            'is-focused': this.isFocused,
            'has-error': this.error,
          };
        },
      },
      input: {
        'x-model': model,
      },
      init() {
        if (this.$refs.input) {
          const value = get(this, model, '');

          this.flatpickr = flatpickr(this.$refs.input, {
            locale: Russian,
            defaultDate: value,
            disableMobile: true,
            static: true,
            minDate: 'today',
            dateFormat: 'd.m.Y',
            altInput: true,
            altFormat: 'l, j M',
            monthSelectorType: 'static',
            prevArrow: '<span class="i i--chevron-left"></span>',
            nextArrow: '<span class="i i--chevron-right"></span>',
            onOpen: () => (this.isInputFocused = true),
            onClose: () => (this.isInputFocused = false),
          });
        }
      },
      destroy() {
        if (this.flatpickr) {
          this.flatpickr.destroy();
        }
      },
    };
  }
}
