import enquire from 'enquire.js';
import { md } from '../variables';
import { disableScroll, enableScroll } from '../lib/scroll';

export function topHeaderMenu(Alpine) {
  function topHeaderMenuComponent() {
    return {
      isOpen: false,
      menu: {
        ['@keyup.esc']() {
          this.isOpen = false;
        },
      },
      trigger: {
        ['@click']() {
          this.isOpen = true;
        },
        [':aria-expanded']() {
          return this.isOpen;
        },
      },
      closeButton: {
        ['@click']() {
          this.isOpen = false;
        },
      },
      list: {
        ['x-ref']: 'list',
      },
      init() {
        this.$watch('isOpen', (isOpen) => {
          if (isOpen) {
            disableScroll();
          } else {
            enableScroll();
          }
        });

        enquire.register(`screen and (min-width: ${md}px)`, {
          match: () => {
            this.isOpen = false;
          },
        });
      },
      close() {
        this.isOpen = false;
      },
    };
  }

  Alpine.data('topHeaderMenu', topHeaderMenuComponent);
}
