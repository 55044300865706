import { Cart, CartItem } from './cart';

export type Event = {};

export type EcommerceEventCategory = 'Enhanced Ecommerce';
export type EcommerceAction =
  | 'Product Clicks'
  | 'Product Impressions'
  | 'Product Details'
  | 'Add to cart'
  | 'Remove from cart'
  | 'Checkout Step 1'
  | 'Checkout Step 2'
  | 'Checkout Step 2 option'
  | 'Checkout Step 3'
  | 'Checkout Step 4'
  | 'Checkout Step 4 option'
  | 'Checkout Step 5'
  | 'Checkout Step 5 option'
  | 'Checkout Step 6'
  | 'Checkout Step 7'
  | 'Purchase';

export type CurrencyCode = 'RUB';

export type EcommerceActionData = {
  id?: string;
  affiliation?: string;
  revenue?: string;
  tax?: number;
  shipping?: number;
  coupon?: string;
  list?: string;
  step?: number;
  option?: string;
};

export type EcommerceImpressionData = {
  id: string;
  name: string;
  list?: string;
  brand?: string;
  category?: string;
  variant?: string;
  position?: number;
  price?: number;
};

export type EcommerceProductData = {
  id: string;
  name: string;
  brand?: string;
  category?: string;
  variant?: string;
  price?: number;
  quantity?: number;
  coupon?: string;
  position?: number;
};

export type EcommerceDetail = {
  actionField?: { list: string };
  products: EcommerceProductData[];
};

export type EcommerceClick = {
  actionField?: { list: string };
  products: EcommerceProductData[];
};

export type Ecommerce = {
  currencyCode?: CurrencyCode;
  click?: EcommerceClick;
  impressions?: EcommerceImpressionData[];
  detail?: EcommerceDetail;
  add?: {
    actionField?: { list: string };
    products: EcommerceProductData[];
  };
  remove?: {
    products: EcommerceProductData[];
  };
  checkout?: {
    actionField: { step: number; option?: string };
    products: EcommerceProductData[];
  };
  checkout_option?: {
    actionField: { step: number; option: string };
  };
  purchase?: {
    actionField: {
      id: string;
      affiliation?: string;
      revenue?: number;
      tax?: number;
      shipping?: number;
      coupon?: string;
    };
    products: EcommerceProductData[];
  };
};

export type DataLayerItem = {
  ecommerce?: Ecommerce;
  event?: 'gtm-ee-event';
  eventCallback?: () => void;
  'gtm-ee-event-category'?: EcommerceEventCategory;
  'gtm-ee-event-action'?: EcommerceAction;
  'gtm-ee-event-non-interaction'?: 'True' | 'False';
};

export type Ga4ItemData = {
  item_id: string;
  item_name: string;
  affiliation?: string;
  coupon?: string;
  discount?: number;
  index?: number;
  item_brand?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_variant?: string;
  location_id?: string;
  price?: number;
  quantity?: number;
};

type Ga4Categories = {
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
};

function category2Ga4(category: string): Ga4Categories {
  const categories = category.split('/');
  const result: Ga4Categories = {};
  if (categories[0]) result.item_category = categories[0];
  if (categories[1]) result.item_category2 = categories[1];
  if (categories[2]) result.item_category3 = categories[2];
  if (categories[3]) result.item_category4 = categories[3];
  if (categories[4]) result.item_category5 = categories[4];
  return result;
}

function getListId(listName) {
  return listName.toLowerCase().replace(' ', '_');
}

function ecommerceItem2Ga4(item: EcommerceImpressionData | EcommerceProductData): Ga4ItemData {
  const result: Ga4ItemData = {
    item_name: item.name,
    item_id: item.id,
    price: item.price,
    item_brand: 'Newflora',
    quantity: 'quantity' in item && item.quantity ? item.quantity : 1,
    ...category2Ga4(item.category),
  };
  if ('list' in item && item.list) {
    result.item_list_name = item.list;
    result.item_list_id = getListId(item.list);

    if ('position' in item) {
      result.index = item.position;
    }
  }

  return result;
}

function ecommerceItems2Ga4(
  items: EcommerceImpressionData[] | EcommerceProductData[]
): Ga4ItemData[] {
  return items.map(ecommerceItem2Ga4);
}

export function cartItemToEcommerceProduct(item: CartItem): EcommerceProductData {
  return {
    id: item.productId.toString(),
    name: item.title,
    price: item.price,
    category: item.ecommerceCategory,
    quantity: item.quantity,
  };
}

export function cartItemsToEcommerceProducts(items: CartItem[]): EcommerceProductData[] {
  return items.map((item) => cartItemToEcommerceProduct(item));
}

export function cartToEcommerceProducts(cart: Cart): EcommerceProductData[] {
  return cartItemsToEcommerceProducts(Object.values(cart));
}

export function cartItemToGa4Item(item: CartItem): Ga4ItemData {
  let data: Ga4ItemData = {
    item_id: item.productId.toString(),
    item_name: item.title,
    price: item.price,
    quantity: item.quantity,
  };

  if (item.ecommerceCategory) {
    data = { ...data, ...category2Ga4(item.ecommerceCategory) };
  }

  if (item.ecommerceList) {
    data.item_list_name = item.ecommerceList;
    data.item_list_id = getListId(item.ecommerceList);
  }

  return data;
}

export function cartItemsToGa4Items(items: CartItem[]): Ga4ItemData[] {
  return items.map((item) => cartItemToGa4Item(item));
}

export function cartToGa4Items(cart: Cart): Ga4ItemData[] {
  return cartItemsToGa4Items(Object.values(cart));
}

export function ecommerceSendSearch(searchTerm: string) {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'search',
    ecommerce: {
      search_term: searchTerm,
    },
  });
}

export function ecommerceSendDetails(product: EcommerceProductData) {
  const ga4Item = ecommerceItem2Ga4(product);
  ga4Item.item_list_name = 'Product card';
  ga4Item.item_list_id = 'product_card';

  const datalayerItem: DataLayerItem = {
    ecommerce: {
      currencyCode: 'RUB',
      detail: {
        products: [product],
      },
    },
    event: 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Product Details',
    'gtm-ee-event-non-interaction': 'True',
  };
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(datalayerItem);
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'view_item',
    ecommerce: {
      items: [ga4Item],
    },
  });
}

let impressionsQueue: EcommerceImpressionData[] = [];
export function processImpressionsQueue() {
  if (!impressionsQueue.length) return;

  const sendAmount = 10;
  const toSend = impressionsQueue.slice(0, sendAmount);

  const dataLayerItem: DataLayerItem = {
    ecommerce: {
      currencyCode: 'RUB',
      impressions: toSend,
    },
    event: 'gtm-ee-event',
    'gtm-ee-event-action': 'Product Impressions',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-non-interaction': 'True',
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(dataLayerItem);
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      items: ecommerceItems2Ga4(toSend),
    },
  });
  impressionsQueue = impressionsQueue.slice(sendAmount);
}

export function sendEcommerceImpressions(impressions: EcommerceImpressionData[]) {
  impressionsQueue = [...impressionsQueue, ...impressions];
}

export function sendImpressionClick(impression: EcommerceImpressionData) {
  const dataLayerItem: DataLayerItem = {
    ecommerce: {
      currencyCode: 'RUB',
      click: {
        actionField: { list: impression.list },
        products: [impression],
      },
    },
    event: 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Product Clicks',
    'gtm-ee-event-non-interaction': 'False',
  };
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(dataLayerItem);
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'select_item',
    ecommerce: {
      items: [ecommerceItem2Ga4(impression)],
    },
  });
}

export function ecommerceAddToWishlist(product: EcommerceImpressionData) {
  const ga4DataLayerItem = {
    event: 'add_to_wishlist',
    ecommerce: {
      items: [ecommerceItem2Ga4(product)],
    },
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(ga4DataLayerItem);
}

export function ecommerceAddProducts(product: EcommerceProductData[], list: string = null) {
  const dataLayerItem: DataLayerItem = {
    ecommerce: {
      currencyCode: 'RUB',
      add: {
        products: product,
      },
    },
    event: 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Add to cart',
    'gtm-ee-event-non-interaction': 'False',
  };

  const ga4DataLayerItem = {
    event: 'add_to_cart',
    ecommerce: {
      items: ecommerceItems2Ga4(product),
    },
  };

  if (list) {
    dataLayerItem.ecommerce.add.actionField = { list };
    ga4DataLayerItem.ecommerce.items[0].item_list_name = list;
    ga4DataLayerItem.ecommerce.items[0].item_list_id = getListId(list);
  }

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(dataLayerItem);
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(ga4DataLayerItem);
}

export function ecommerceRemoveProducts(cartItems: CartItem[]) {
  const products = cartItemsToEcommerceProducts(cartItems);
  const ga4Items = cartItemsToGa4Items(cartItems);

  const dataLayerItem: DataLayerItem = {
    ecommerce: {
      currencyCode: 'RUB',
      remove: {
        products: products,
      },
    },
    event: 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Remove from cart',
    'gtm-ee-event-non-interaction': 'False',
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(dataLayerItem);
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      items: ga4Items,
    },
  });
}

export function ecommerceCheckoutStart(cart: Cart) {
  const ecommerceItems = cartToEcommerceProducts(cart);
  const ga4Items = cartToGa4Items(cart);

  const dataLayerItem: DataLayerItem = {
    ecommerce: {
      currencyCode: 'RUB',
      checkout: {
        actionField: { step: 1 },
        products: ecommerceItems,
      },
    },
    event: 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Checkout Step 1',
    'gtm-ee-event-non-interaction': 'False',
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(dataLayerItem);
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'view_cart',
    ecommerce: {
      items: ga4Items,
    },
  });
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      items: ga4Items,
    },
  });
}

export function ecommerceCheckoutAddingAdditionalProduct(cart: Cart) {
  const dataLayerItem: DataLayerItem = {
    ecommerce: {
      currencyCode: 'RUB',
      checkout: {
        actionField: { step: 2 },
        products: cartToEcommerceProducts(cart),
      },
    },
    event: 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Checkout Step 2',
    'gtm-ee-event-non-interaction': 'False',
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(dataLayerItem);
}

export function ecommerceCheckoutFillingContacts(cart: Cart) {
  const dataLayerItem: DataLayerItem = {
    ecommerce: {
      currencyCode: 'RUB',
      checkout: {
        actionField: { step: 3 },
        products: cartToEcommerceProducts(cart),
      },
    },
    event: 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Checkout Step 3',
    'gtm-ee-event-non-interaction': 'False',
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(dataLayerItem);
}

export function ecommerceCheckoutSelectingDelivery(cart: Cart) {
  const dataLayerItem: DataLayerItem = {
    ecommerce: {
      currencyCode: 'RUB',
      checkout: {
        actionField: { step: 4 },
        products: cartToEcommerceProducts(cart),
      },
    },
    event: 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Checkout Step 4',
    'gtm-ee-event-non-interaction': 'False',
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(dataLayerItem);
}

export function ecommerceCheckoutSelectDeliveryOption(delivery: string, cart: Cart) {
  const ga4Items = cartToGa4Items(cart);

  const dataLayerItem: DataLayerItem = {
    ecommerce: {
      checkout_option: {
        actionField: {
          step: 4,
          option: delivery,
        },
      },
    },
    event: 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Checkout Step 4 option',
    'gtm-ee-event-non-interaction': 'False',
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(dataLayerItem);
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'add_shipping_info',
    ecommerce: {
      shipping_tier: delivery,
      items: ga4Items,
    },
  });
}

export function ecommerceCheckoutSelectingPayment(cart: Cart) {
  const dataLayerItem: DataLayerItem = {
    ecommerce: {
      currencyCode: 'RUB',
      checkout: {
        actionField: { step: 5 },
        products: cartToEcommerceProducts(cart),
      },
    },
    event: 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Checkout Step 5',
    'gtm-ee-event-non-interaction': 'False',
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(dataLayerItem);
}

export function ecommerceCheckoutSelectPaymentOption(payment: string, cart: Cart) {
  const ga4Items = cartToGa4Items(cart);

  const dataLayerItem: DataLayerItem = {
    ecommerce: {
      checkout_option: {
        actionField: {
          step: 5,
          option: payment,
        },
      },
    },
    event: 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Checkout Step 5 option',
    'gtm-ee-event-non-interaction': 'False',
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(dataLayerItem);
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'add_payment_info',
    ecommerce: {
      payment_type: payment,
      items: ga4Items,
    },
  });
}

export function ecommerceCheckoutSubmit(cart: Cart) {
  const dataLayerItem: DataLayerItem = {
    ecommerce: {
      currencyCode: 'RUB',
      checkout: {
        actionField: { step: 6 },
        products: cartToEcommerceProducts(cart),
      },
    },
    event: 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Checkout Step 6',
    'gtm-ee-event-non-interaction': 'False',
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(dataLayerItem);
}

export function ecommerceCheckoutComplete(
  id: string,
  revenue: number,
  shipping: number,
  cartItems: CartItem[]
) {
  const products = cartItemsToEcommerceProducts(cartItems);
  const ga4Items = cartItemsToGa4Items(cartItems);
  const dataLayerCheckoutItem: DataLayerItem = {
    ecommerce: {
      currencyCode: 'RUB',
      checkout: {
        actionField: { step: 7 },
        products,
      },
    },
    event: 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Checkout Step 7',
    'gtm-ee-event-non-interaction': 'False',
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(dataLayerCheckoutItem);

  const dataLayerPurchaseItem: DataLayerItem = {
    ecommerce: {
      currencyCode: 'RUB',
      purchase: {
        actionField: {
          id,
          affiliation: 'Newflora',
          revenue,
          shipping,
        },
        products,
      },
    },
    event: 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Purchase',
    'gtm-ee-event-non-interaction': 'False',
  };

  dataLayer.push({ ecommerce: null });
  dataLayer.push(dataLayerPurchaseItem);

  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: String(id),
      affiliation: 'Online',
      value: revenue,
      shipping: shipping,
      currency: 'RUB',
      items: ga4Items,
    },
  });
}
